type ClickOutsideCallback = () => void;

const handleClickOutside = (element: HTMLElement, callback: ClickOutsideCallback) => {
  const eventHandler = (e: MouseEvent) => {
    if (!element.contains(e.target as Node)) {
      callback();
      document.removeEventListener('click', eventHandler); // Remove the event listener after the callback is invoked
    }
  };
  return eventHandler;
};

export const onClickOutside = (element: HTMLElement, callback: ClickOutsideCallback) => {
  const eventHandler = handleClickOutside(element, callback);

  // Use setTimeout to ensure that the event handler is added after the current event has been handled
  setTimeout(() => {
    document.addEventListener('click', eventHandler);
  }, 0);

  return eventHandler; // Return the handler to be used for removal if needed
};